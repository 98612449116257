import { Currencies } from '../../../../../types';
import { CompanyNanoID } from '../../../../companies/companiesTypes';
import { ProjectNanoID } from '../../../../projects/projectsTypes';
import { MultiSelectGroupedDataType } from '../../../../../helpers/MultiSelect/types';

export interface ProformaInvoiceFormData {
  companyNanoId: CompanyNanoID;
  currency?: Currencies;
  viewMode?: ProformaInvoiceFormViewMode;
  enableGrouping?: boolean;
  groupBy?: ProformaInvoiceFormGroupByMode;
  mergeSimilar?: boolean;
}

export const enum ProformaInvoiceFormFields {
  COMPANY_NANO_ID = 'companyNanoId',
  CURRENCY = 'currency',
  VIEW_MODE = 'viewMode',
  ENABLE_GROUPING = 'enableGrouping',
  GROUP_BY = 'groupBy',
  MERGE_SIMILAR = 'mergeSimilar'
}

export const enum ProformaInvoiceFormGroupByMode {
  BATCH = 'batch',
  BATCH_TASK = 'batchTask',
  INVOICE_TAG = 'invoiceTag',
  MONTH_INVOICE_CREATED = 'monthInvoiceCreated',
  MONTH_PROJECT_CLOSED = 'monthProjectClosed',
  MONTH_PROJECT_CREATED = 'monthProjectCreated',
  MONTH_PROJECT_CREATED_PROJECT = 'monthProjectCreatedProject',
  MONTH_TASK_CREATED = 'monthTaskCreated',
  MONTH_TASK_CREATED_TASK = 'monthTaskCreatedTask',
  MONTH_TASK_DONE = 'monthTaskDone',
  MONTH_TASK_DUE_DATE = 'monthTaskDueDate',
  MONTH_TASK_DUE_DATE_TASK = 'monthTaskDueDateTask',
  PROJECT = 'project',
  PROJECT_AND_TASK_VERSIONS = 'projectAndTaskVersions',
  PROJECT_TASK = 'projectTask',
  PROJECT_SPLIT = 'projectSplit',
  SPLIT = 'split',
  TASK = 'task'
}

export const enum ProformaInvoiceFormViewMode {
  INVOICES = 'invoices',
  TASKS = 'tasks',
  PROJECTS = 'projects',
  CATEGORIES = 'categories'
}

export type ProformaSettings = {
  viewMode: ProformaInvoiceFormViewMode;
  groupBy: ProformaInvoiceFormGroupByMode;
  enableGrouping: boolean;
  mergeSimilar: boolean;
};

export const defaultProformaSettings = {
  viewMode: ProformaInvoiceFormViewMode.INVOICES,
  groupBy: ProformaInvoiceFormGroupByMode.SPLIT,
  enableGrouping: true,
  mergeSimilar: true
};

export const proformaSettingsLocalStoreKey = (
  companyNanoId: CompanyNanoID,
  projectNanoId?: ProjectNanoID
) =>
  `company-${companyNanoId}${
    projectNanoId ? '-projects' : ''
  }-payments-proforma-settings`;

export type ProformaInvoiceFormGroupByOptions = Record<
  Exclude<ProformaInvoiceFormViewMode, ProformaInvoiceFormViewMode.CATEGORIES>,
  MultiSelectGroupedDataType[]
>;
