import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../common/hooks/useTableCheckable';
import {
  BooleanFilter,
  CreatedAt,
  CreateItemGqlQuery,
  CreateItemGqlStatus,
  DateType,
  FetchItemCacheKey,
  FetchItemGqlQuery,
  FetchItemsCacheKey,
  FetchItemsEditItem,
  FetchItemsClearItemsFilters,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFilterItems,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  ID,
  IdFilterType,
  NumberFilterType,
  Item,
  ItemGqlError,
  NanoID,
  TextFilterType,
  UpdatedAt,
  UpdateItemCacheKeys,
  UpdateItemGqlQuery,
  UpdateItemGqlStatus,
  UUID,
  FetchItemFetched,
  FetchItemIsPlaceholderData,
  Locale,
  NanoIdFilterType,
  UuidFilterType
} from '../../types';
import {
  CategoryID,
  CategoryLocalizedName
} from '../categories/categoriesTypes';
import { FinanceUserTagName } from '../financeUserTags/financeUserTagsTypes';
import { UpdateIndexQueryItemCacheAction } from '../common/hooks/base/reactQuery/useIndexQuery';
import { ImageFile, ImageID, ImageUUID } from '../images/imagesTypes';
import {
  FileAttachmentID,
  FileAttachmentUUID,
  FileAttachmentFile,
  FileAttachmentName
} from '../fileAttachments/fileAttachmentsTypes';
import { RoleID } from '../roles/rolesTypes';
import { SaveSearchFilterFunc } from '../searchFilters/searchFiltersTypes';
import { TagID } from '../tags/tagsTypes';
import {
  TeamsContractorContractorID,
  TeamsContractorContractorUUID
} from '../teamsContractors/teamsContractorsTypes';
import { TeamsUpsalerUpsalerID } from '../teamsUpsalers/teamsUpsalerTypes';
import {
  UserFinanceRoleName,
  UserFullName,
  UserID,
  UserImageFile,
  UserNanoID,
  UserUUID,
  UserClient,
  UserCurrentTeamNanoID,
  UserFinanceRoleId,
  UserWorkExperience,
  UserCity,
  UserEmail,
  UserTotalHoursDay,
  UserLastOperationType,
  UserLastOperationCreatedAt,
  UserLastOperationBody,
  UserLastOperationSubject,
  UserPayoneerPayoutsBlocked
} from '../users/usersTypes';
import {
  InvoiceNanoID,
  InvoicePaymentMethods
} from '../invoices/invoicesTypes';
import { UpdateItemsGqlQuery } from '../../types/updateItemsTypes';
import { CompanyNanoID } from '../companies/companiesTypes';
import { AccountBalance } from '../accounts/accountsTypes';
import { UserCompetencyValue } from '../userCompetencies/userCompetenciesTypes';
import { TaskState } from '../tasks/tasksTypes';

export enum TeamTeamTypeEnum {
  CLIENTS = 'clients',
  WORKERS = 'workers'
}

export enum TeamFilesStorages {
  AWS = 'aws',
  DO = 'do'
}

export type TeamID = ID;
export type TeamIDs = TeamID[];
export type TeamUUID = UUID;
export type TeamNanoID = NanoID;
export type TeamName = string;
export type TeamEmail = string;
export type TeamDescription = string;
export type TeamDefaultTaskState = TaskState;
export type TeamGeneralLedgerID = ID;
export type TeamNda = boolean;
export type TeamTeamType = TeamTeamTypeEnum;
export type TeamUseLdApiAt = UpdatedAt;
export type TeamUserID = UserID;
export type TeamUserUUID = UserUUID;
export type TeamUserNanoID = UserNanoID;
export type TeamUserFullName = UserFullName;
export type TeamContractorID = UserID;
export type TeamContractorUUID = UserUUID;
export type TeamContractorNanoID = UserNanoID;
export type TeamImageId = ImageID;
export type TeamImageUUID = ImageUUID;
export type TeamUserImageFile = UserImageFile;
export type TeamUserClient = UserClient;
export type TeamUserCurrentTeamNanoID = UserCurrentTeamNanoID;
export type TeamUsersUUID = UserUUID;
export type TeamUsersID = UserID;
export type TeamUsersNanoID = UserNanoID;
export type TeamUsersFullName = UserFullName;
export type TeamUsersImageFile = UserImageFile;
export type TeamUsersFinanceRoleName = UserFinanceRoleName;
export type TeamUsersClient = UserClient;
export type TeamUsersCurrentTeamNanoID = UserCurrentTeamNanoID;
export type TeamContractorsContractorID = TeamsContractorContractorID;
export type TeamContractorsContractorUUID = TeamsContractorContractorUUID;
export type TeamFinanceValues = TeamFinanceValuesType;
export type TeamUpsalersUpsalerID = TeamsUpsalerUpsalerID;
export type TeamUpsalersUpsalerFullName = UserFullName;
export type TeamUpsalerID = UserID;
export type TeamUpsalerUUID = UserUUID;
export type TeamUpsalerNanoID = UserNanoID;
export type TeamSaleManagerID = UserID;
export type TeamSaleManagerUUID = UserUUID;
export type TeamSaleManagerNanoID = UserNanoID;
export type TeamUpdatedAt = UpdatedAt;
export type TeamCreatedAt = CreatedAt;
export type TeamAutoRepaymentEnabledAt = DateType;
export type TeamBlockedAt = DateType;
export type TeamClientStatus = TeamClientStatusesEnum;
export type TeamCoefficient = number;
export type TeamCountryId = ID;
export type TeamCurrency = string;
export type TeamDiscount = string;
export type TeamAdditionalDiscount = string;
export type TeamCity = string;
export type TeamRevenue = AccountBalance;
export type TeamRevenueReverse = AccountBalance;
export type TeamShare = UserCompetencyValue;
export type TeamUsersTasksInProgressCount = number;
export type TeamOwnerID = UserID;
export type TeamOwnerUUID = UserUUID;
export type TeamOwnerNanoID = UserNanoID;
export type TeamOwnerFullName = UserFullName;
export type TeamOwnerImageFile = UserImageFile;
export type TeamOwnerFinanceRoleID = UserFinanceRoleId;
export type TeamOwnerFinanceRoleName = UserFinanceRoleName;
export type TeamOwnerGroupLocalizedName = CategoryLocalizedName;
export type TeamOwnerPositionLocalizedNName = CategoryLocalizedName;
export type TeamOwnerFinanceTagName = FinanceUserTagName;
export type TeamOwnerProjectManagerFullName = UserFullName;
export type TeamOwnerMentorFullName = UserFullName;
export type TeamOwnerWorkExperience = UserWorkExperience;
export type TeamOwnerCity = UserCity;
export type TeamOwnerEmail = UserEmail;
export type TeamOwnerTotalHoursDay = UserTotalHoursDay;
export type TeamOwnerQualificationLocalizedName = CategoryLocalizedName;
export type TeamOwnerLastOperationType = UserLastOperationType;
export type TeamOwnerLastOperationCreatedAt = UserLastOperationCreatedAt;
export type TeamOwnerLastOperationBody = UserLastOperationBody;
export type TeamOwnerLastOperationSubject = UserLastOperationSubject;
export type TeamOwnerPayoneerPayoutsBlocked = UserPayoneerPayoutsBlocked;

export type TeamCreator = {
  id: TeamUserID;
  nanoId: TeamUserNanoID;
  fullName: TeamUserFullName;
  image: {
    uuid: TeamImageUUID;
    file: TeamUserImageFile;
    fileName: TeamUserImageFile;
  };
};
export type TeamSite = string;
export type TeamStateID = ID;
export type TeamStateName = string;
export type TeamAddress = string;
export type TeamAutoPaymentScAt = DateType;
export type TeamFacebook = string;
export type TeamLinkedIn = string;
export type TeamTwitter = string;
export type TeamInstagram = string;
export type TeamSocialNetworks = string;
export type TeamFavorite = boolean;
export type TeamFirstPaymentDate = DateType;
export type TeamLastPaymentDate = DateType;
export type TeamLeadCreationDate = DateType;
export type TeamImageFile = ImageFile;
export type TeamTagsTagID = TagID;
export type TeamValidateOutput = TeamValidateOutputEnum;
export type TeamCompanyCrm = string;
export type TeamManualTeamStatus = TeamManualTeamStatusesEnum;
export type TeamBusinessEmail = string;
export type TeamZipCode = string;
export type TeamReferer = string;
export type TeamMarketingSourceID = CategoryID;
export type TeamColdSourceID = CategoryID;
export type TeamCategoryID = CategoryID;
export type TeamSubCategoryID = CategoryID;
export type TeamSubCategoriesSubCategoryID = CategoryID;
export type TeamCompanyRevenueID = CategoryID;
export type TeamNatureOfLeadID = CategoryID;
export type TeamCompanySizeID = CategoryID;
export type TeamRoleID = RoleID;
export type TeamLocale = Locale;
export type TeamTimeZone = string;
export type TeamTimeFormat = string;
export type TeamDateFormat = string;
export type TeamOperationHours = string;
export type TeamCdn = string;
export type TeamPaymentMethodID = ID;
export type TeamViewPerTask = string;
export type TeamRevisions = string;
export type TeamHourlyRate = string;
export type TeamDeliveryTime = string;
export type TeamRespondTime = string;
export type TeamSource = string;
export type TeamWorkflowTypeID = CategoryID;
export type TeamPriority = string;
export type TeamAccountReceivables = number;
export type TeamDebtLimit = number;
export type TeamDeferredPayment = number;
export type TeamHoldRate = number;
export type TeamTransactionFee = number;
export type TeamProvisionForBadDebt = number;
export type TeamPreferredPaymentMethod = InvoicePaymentMethods;
export type TeamProvisionForRevision = number;
export type TeamTerms = string;
export type TeamPrefferedPaymentMethod = TeamPreferredPaymentMethod;
export type TeamTagsLocalizedName = string;
export type TeamSubCategoryLocalizedName = string;
export type TeamCategoryLocalizedName = string;
export type TeamBalance = number;
export type TeamDebt = number;
export type TeamSigmaPayments = number;

export type TeamNdaFileID = FileAttachmentID;
export type TeamNdaFileUUID = FileAttachmentUUID;
export type TeamNdaFileName = FileAttachmentName;
export type TeamNdaFileFile = FileAttachmentFile;
export type TeamVendorFormID = FileAttachmentID;
export type TeamVendorFormUUID = FileAttachmentUUID;
export type TeamVendorFormName = FileAttachmentName;
export type TeamVendorFormFile = FileAttachmentFile;
export type TeamAgreementID = FileAttachmentID;
export type TeamAgreementUUID = FileAttachmentUUID;
export type TeamAgreementName = FileAttachmentName;
export type TeamAgreementFile = FileAttachmentFile;

type TeamRealDeclineReason = { id: CategoryID };
export type TeamRealDeclineReasons = TeamRealDeclineReason[];
type TeamComplexity = { id: CategoryID };
export type TeamComplexities = TeamComplexity[];
type TeamScopeOfWork = { id: CategoryID };
export type TeamScopeOfWorks = TeamScopeOfWork[];

export type FetchTeamCacheKey = FetchItemCacheKey;
export type FetchTeamGqlQuery = FetchItemGqlQuery;

export type CreateTeamGqlQuery = CreateItemGqlQuery;
export type CreateTeamGqlStatus = CreateItemGqlStatus;

export type UpdateTeamCacheKeys = UpdateItemCacheKeys;
export type UpdateTeamGqlQuery = UpdateItemGqlQuery;
export type UpdateTeamGqlStatus = UpdateItemGqlStatus;
export type UpdateTeamCacheAction = string;

export type BlockTeamGqlQuery = UpdateItemsGqlQuery;
export type BlockTeamGqlStatus = UpdateItemGqlStatus;
export type BlockTeamGqlError = ItemGqlError;
export type BlockTeamGqlErrors = BlockTeamGqlError[];

export type UnblockTeamGqlQuery = UpdateItemsGqlQuery;
export type UnblockTeamGqlStatus = UpdateItemGqlStatus;
export type UnblockTeamGqlError = ItemGqlError;
export type UnblockTeamGqlErrors = BlockTeamGqlError[];

export type SubmitFileAttachmentsToTeamGqlQuery = CreateItemGqlQuery;
export type SubmitFileAttachmentsToTeamGqlStatus = CreateItemGqlStatus;

export type FetchTeamsCacheKey = FetchItemsCacheKey;
export type FetchTeamsGqlQuery = FetchItemsGqlQuery;

export interface FetchTeamsFilters {
  bitClientStatus?: TextFilterType;
  categoryId?: IdFilterType;
  clientStatus?: TextFilterType;
  companyContractorNanoId?: NanoIdFilterType;
  favorite?: BooleanFilter;
  financeRoleId?: IdFilterType;
  financeTagId?: IdFilterType;
  groupId?: IdFilterType;
  id?: IdFilterType;
  uuid?: UuidFilterType;
  mentorId?: IdFilterType;
  name?: TextFilterType;
  nameOrOwnerName?: TextFilterType;
  nameOrUsersEmail?: TextFilterType;
  nanoId?: NanoIdFilterType;
  ownerBlocked?: BooleanFilter;
  ownerId?: IdFilterType;
  ownerEmailOrName?: TextFilterType;
  payoneerPayoutBlocked?: BooleanFilter;
  pmOrMentorNanoId?: NanoIdFilterType;
  positionId?: IdFilterType;
  projectManagerId?: IdFilterType;
  qualificationId?: IdFilterType;
  revenue?: NumberFilterType;
  share?: NumberFilterType;
  smNanoId?: NanoIdFilterType;
  specializationId?: IdFilterType;
  subCategoryId?: IdFilterType;
  tagId?: IdFilterType;
  teamType?: TextFilterType;
  upsalerId?: IdFilterType;
  workExperience?: NumberFilterType;
}

export type FetchTeamsClearTeamsFilters = FetchItemsClearItemsFilters;
export type FetchTeamsFilterTeams = FetchItemsFilterItems<FetchTeamsFilters>;
export type FetchTeamsFiltersFunc = (nextFilters: FetchTeamsFilters) => void;
export type TeamsCreateSearchFiltersFunc =
  SaveSearchFilterFunc<FetchTeamsFilters>;

export const enum FetchTeamsFilterFields {
  TEAM_TYPE = 'teamType',
  UPSALER_ID = 'upsalerId.in',
  FAVORITE = 'favorite',
  CLIENT_STATUS = 'clientStatus',
  CATEGORY_ID = 'categoryId',
  SUBCATEGORY_ID = 'subCategoryId',
  TAG_ID = 'tagId',
  GROUP_ID = 'groupId',
  POSITION_ID = 'positionId',
  SPECIALIZATION_ID = 'specializationId',
  QUALIFICATION_ID = 'qualificationId',
  FINANCE_TAG_ID = 'financeTagId',
  MENTOR_ID = 'mentorId',
  PROJECT_MANAGER_ID = 'projectManagerId',
  FINANCE_ROLE_ID = 'financeRoleId',
  SHARE = 'share',
  REVENUE = 'revenue',
  WORK_EXPERIENCE = 'workExperience',
  NAME_OR_OWNER_NAME = 'nameOrOwnerName',
  NAME_OR_USERS_EMAIL = 'nameOrUsersEmail',
  PAYONEER_PAYOUT_BLOCKED = 'payoneerPayoutBlocked'
}

export const enum FetchTeamsSortItem {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_DESC = 'NAME_DESC',
  NAME_ASC = 'NAME_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CLIENT_STATUS_ASC = 'CLIENT_STATUS_ASC',
  CLIENT_STATUS_DESC = ' CLIENT_STATUS_DESC',
  OWNER_FINANCE_ROLE_NAME_ASC = 'OWNER_FINANCE_ROLE_NAME_ASC',
  OWNER_FINANCE_ROLE_NAME_DESC = 'OWNER_FINANCE_ROLE_NAME_DESC',
  OWNER_POSITION_ASC = 'OWNER_POSITION_ASC',
  OWNER_POSITION_DESC = 'OWNER_POSITION_DESC',
  OWNER_PROJECT_MANAGER_NAME_ASC = 'OWNER_PROJECT_MANAGER_NAME_ASC',
  OWNER_PROJECT_MANAGER_NAME_DESC = 'OWNER_PROJECT_MANAGER_NAME_DESC',
  OWNER_WORK_EXPERIENCE_ASC = 'OWNER_WORK_EXPERIENCE_ASC',
  OWNER_WORK_EXPERIENCE_DESC = 'OWNER_WORK_EXPERIENCE_DESC'
}

export const enum FetchTeamsSortableFields {
  ID = 'id',
  NAME = 'name',
  CLIENT_STATUS = 'clientStatus',
  OWNER_FINANCE_ROLE_NAME = 'ownerFinanceRoleName',
  OWNER_POSITION = 'ownerPosition',
  OWNER_PROJECT_MANAGER_NAME = 'ownerProjectManagerName',
  OWNER_WORK_EXPERIENCE = 'ownerWorkExperience'
}

export const enum TeamFields {
  ACCOUNT_RECEIVABLE = 'accountReceivables',
  ADDITIONAL_DISCOUNT = 'additionalDiscount',
  ADDRESS = 'address',
  AGREEMENT_ID = 'agreementId',
  AUTO_REPAYMENT_ENABLED_AT = 'autoRepaymentEnabledAt',
  BUSINESS_EMAIL = 'businessEmail',
  CATEGORY_ID = 'categoryId',
  CDN = 'cdn',
  CITY = 'city',
  CLIENTS = 'clients',
  COLD_SOURCE_ID = 'coldSourceId',
  COMPANY_CRM = 'companyCrm',
  COMPANY_REVENUE_ID = 'companyRevenueId',
  COMPANY_SIZE_ID = 'companySizeId',
  COMPLEXITY_IDS = 'complexityIds',
  CONTRACTOR_IDS = 'contractorIds',
  COUNTRY_ID = 'countryId',
  CURRENCY = 'currency',
  DATE_FORMAT = 'dateFormat',
  DEBT_LIMIT = 'debtLimit',
  DEFAULT_TASK_STATE = 'defaultTaskState',
  DEFERRED_PAYMENT = 'deferredPayment',
  DELIVERY_TIME = 'deliveryTime',
  DESCRIPTION = 'description',
  DISCOUNT = 'discount',
  EMAIL = 'email',
  FACEBOOK = 'facebook',
  FILES_STORAGE = 'filesStorage',
  HOLD_RATE = 'holdRate',
  HOURLY_RATE = 'hourlyRate',
  IMAGE_ID = 'imageId',
  INSTAGRAM = 'instagram',
  LEAD_CREATION_DATE = 'leadCreationDate',
  LINKED_IN = 'linkedIn',
  LOCALE = 'locale',
  MANUAL_TEAM_STATUS = 'manualTeamStatus',
  MARKETING_SOURCE_ID = 'marketingSourceId',
  NAME = 'name',
  NATURE_OF_LEAD_ID = 'natureOfLeadId',
  NDA = 'nda',
  NDA_FILE_ID = 'ndaFileId',
  OPERATION_HOURS = 'operationHours',
  OWNER_ID = 'ownerId',
  PAYMENT_METHOD_IDS = 'paymentMethodIds',
  PREFERRED_PAYMENT_METHOD = 'preferredPaymentMethod',
  PRIORITY = 'priority',
  PROVISION_FOR_BAD_DEBT = 'provisionForBadDebt',
  PROVISION_FOR_REVISION = 'provisionForRevision',
  REAL_DECLINE_REASON_IDS = 'realDeclineReasonIds',
  REFERER = 'referer',
  RESPOND_TIME = 'respondTime',
  REVISIONS = 'revisions',
  ROLE_IDS = 'roleIds',
  SALES_MANAGER_IDS = 'salesManagerIds',
  SCOPE_OF_WORK_IDS = 'scopeOfWorkIds',
  SITE = 'site',
  SOCIAL_NETWORKS = 'socialNetworks',
  SOURCE = 'source',
  SPLIT_PART_PERCENTS = 'splitPartPercents',
  STATE_ID = 'stateId',
  STATE_NAME = 'stateName',
  SUB_CATEGORY_ID = 'subCategoryId',
  SUB_CATEGORY_IDS = 'subCategoryIds',
  TAG_IDS = 'tagIds',
  TEAM_TYPE = 'teamType',
  TERMS = 'terms',
  TIME_FORMAT = 'timeFormat',
  TIME_ZONE = 'timeZone',
  TRANSACTION_FEE = 'transactionFee',
  TWITTER = 'twitter',
  UPSALERS = 'upsalers',
  USE_LD_API_AT = 'useLdApiAt',
  USER_ID = 'userId',
  VALIDATE_OUTPUT = 'validateOutput',
  VENDOR_FORM_ID = 'vendorFormId',
  VIEW_PER_TASK = 'viewPerTask',
  WORKFLOW_TYPE_ID = 'workflowTypeId',
  ZIP_CODE = 'zipCode'
}

export type FetchTeamsSort = FetchItemsSort;
export type FetchTeamsSortTeams = FetchItemsSortItems;
export type FetchTeamsPage = FetchItemsPage;
export type FetchTeamsLimit = FetchItemsLimit;
export type FetchTeamsErrorMessage = FetchItemsErrorMessage;
export type FetchTeamsFetched = FetchItemsFetched;
export type FetchTeamFetched = FetchItemFetched;
export type FetchTeamsIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchTeamIsPlaceholderData = FetchItemIsPlaceholderData;
export type FetchTeamsPaginateTeams = FetchItemsPaginateItems;
export type FetchTeamsPrefetchTeams = FetchItemsPrefetchItems;
export type FetchTeamsTotalCount = FetchItemsTotalCount;

export type FetchTeamsCheckedAll = CheckedAll;
export type FetchTeamsOnCheckAll = OnCheckAll;
export type FetchTeamsChecked = Checked;
export type FetchTeamsCheckedHash = CheckedHashItem;
export type FetchTeamsOnSetCheckedIds = OnSetCheckedIds;

export type FetchTeamsEditTeam = FetchItemsEditItem;

export type ChangeTeamsFiltersFunc = (
  changedFilters: FetchTeamsFilters,
  removeFilters?: string[]
) => void;

export type TeamGqlError = ItemGqlError;

export type UpdatePaginatedTeamsItemCacheAction<ItemType> =
  UpdateIndexQueryItemCacheAction<ItemType>;

export type AfterCreateCompanyInvoiceAction = (
  companyNanoId: CompanyNanoID,
  invoiceNanoId: InvoiceNanoID
) => void;

export interface TeamType extends Item {
  name: string;
  description?: string;
  teamType: string;
}

export enum TeamClientStatusesEnum {
  NEW = 'new',
  ACTIVE = 'active',
  PAUSE = 'paused',
  DECLINED = 'declined',
  REAL_DECLINE = 'real_decline',
  NO_PAYMENTS = 'no_payments'
}

export enum TeamManualTeamStatusesEnum {
  NEW = 'new',
  ACTIVE = 'active',
  PAUSE = 'paused',
  DECLINED = 'declined',
  REAL_DECLINE = 'real_decline',
  NO_PAYMENTS = 'no_payments'
}

export enum TeamValidateOutputEnum {
  YES = 'yes',
  NO = 'no',
  NONE = 'none'
}

type TeamFinanceValuesType = {
  balance_amount: number | null;
  bonus_account: number | null;
  client_discussions_count: number | null;
  client_rate_average: number | null;
  client_rate_median: number | null;
  client_tracked_seconds: number | null;
  credit_account: number | null;
  debit_account: number | null;
  debt: number | null;
  discussions_account_receivable: number | null;
  discussions_role_debt: number | null;
  payment_bills_amount: number | null;
  prepaid_balance_amount: number | null;
  total_amount: number | null;
  turn_around_time: number | null;
  user_balance: number | null;
  worker_rate_average: number | null;
  worker_rate_median: number | null;
};
