export default {
  accountTransactionCount: 'Account Transaction Count',
  addFunds: 'Add funds',
  addBillingInformationToCreateAnInvoice:
    'Add billing information to create an invoice.',
  addANote: 'Add a note...',
  addToDraft: 'Add to draft',
  addInvoiceTag: 'Add invoice tag',
  allInvoices: 'All invoices',
  allItemsBelowAreExpectedToBePaidHoweverYouMayChooseSpecificItemsToBeIncludedInTheCreatedOpenInvoice:
    'All items below are expected to be paid. However, you may choose specific items to be included in the created open invoice. All unchecked items will stay in the preliminary invoice for you to pay with the future open invoice(s).',
  allSmartContractsContainingItemsFromThisInvoiceWillBeCanceledAreYouSureYouWantToCancelSmartContracts:
    'All smart contracts containing items from this invoice will be canceled. Are you sure you want to cancel smart contracts?',
  amount: 'Amount',
  anotherAddress: 'Another address',
  archivizerAccount: 'Archivizer account',
  areYouSureYouWantToCreateInvoice: 'Are you sure you want to create invoice?',
  areYouSureYouWantToCancelThisInvoice:
    'Are you sure you want to cancel this іnvoice?',
  areYouSureYouWantToCancelSmartContractsThisInvoice:
    'Are you sure you want to cancel smart contracts this іnvoice?',
  areYouSureYouWantToUncollectibleThisInvoice:
    'Are you sure you want to uncollectible this іnvoice?',
  areYouSureYouWantToSendThisInvoice:
    'Are you sure you want to send this іnvoice?',
  areYouSureYouWantToApproveThisInvoice:
    'Are you sure you want to approve this іnvoice?',
  areYouSureYouWantToPayFromDeposit:
    'Are you sure you want to pay from deposit?',
  avInvoiceBillingInfoPaymentMethod: 'AV invoice billing info payment method',
  areYouSureYouWantToCheckAndPayThisInvoice:
    'Are you sure you want to check and pay this іnvoice?',
  billingAddress: 'Billing address',
  billingInfo: 'Billing info',
  cancel: 'Cancel invoice',
  cancelSmartContracts: 'Cancel smart contracts',
  cardInfo: 'Card info',
  chargeback: 'Chargeback',
  clientId: 'Client Id',
  companyName: 'Company name',
  confirmITC: 'Confirm ITC',
  confirmPayment: 'Confirm payment',
  confirmInvoicePayment: 'Confirm invoice payment',
  contactEmailAddress: 'Contact email address',
  contactName: 'Contact name',
  contractor: 'Contractor',
  contactPhoneNumber: 'Contact phone number',
  create: 'Create invoice',
  createNewDraft: 'Create new draft',
  created: 'created invoice',
  createYourInvoices: 'Create your invoices',
  creditCard: 'Credit card',
  checkAndPay: 'Check and pay',
  checkAndPayV4: 'Check and pay (v4)',
  date: 'Invoice date',
  details: 'Invoice details',
  deposit: 'Deposit',
  depositInvoices: 'Deposit invoices',
  draftName: 'Draft name',
  dueDate: 'Due date',
  dueDateColon: 'Due date:',
  filter: 'Filter invoices',
  generalLedger: 'General ledger',
  generalLedgerId: 'General ledger id',
  id: 'Invoice ID',
  ids: 'Invoice IDs',
  invoiceCreated: 'Invoice created',
  invoiceBillingEmail: 'Invoice billing email',
  invoiceHasNoItems: 'This invoice has no items',
  issueDate: 'Issue date',
  issueDateColon: 'Issue date:',
  notes: 'Notes',
  notesColon: 'Notes:',
  notOverdue: 'Not overdue:',
  otherInvoices: 'Other Invoices',
  openProforma: 'Open proforma',
  overdue: 'Overdue:',
  plural: 'Invoices',
  parentInvoiceId: 'Parent Invoice Id',
  pluralLowerCase: 'invoices',
  primaryInvoiceId: 'Primary Invoice Id',
  refreshStatus: 'Refresh status',
  refund: 'Refund',
  refunded: 'Refunded',
  regular: 'Regular',
  removeFromDraft: 'Remove from draft',
  secondaryInvoiceId: 'Secondary invoice ID',
  send: 'Send invoice',
  sendToClient: 'Send invoice to the client',
  singular: 'Invoice',
  terms: 'Terms',
  total: 'Invoice total',
  termsColon: 'Terms:',
  type: 'Invoice type',
  userId: 'User ID',
  messages: {
    areYouSureYouWantToCancelInvoice:
      'Are you sure you want to cancel invoice?',
    creditCardMethods: 'Visa/Mastercard, Payoneer, Stripe, EasyPay, Fondy.eu',
    onceWeReceiveYourPayment:
      'Once we receive your payment, funds are available on your deposit',
    payoneerWarning:
      'By using the Payoneer Services through the Online Pages and APIs, I agree that any information and data related to payments made through the API and any information provided by me on the Online Pages will be transmitted and processed by Payoneer, and may also be available to Company.',
    stripeWarning: 'You can pay with the Stripe payment system only on ',
    weHaveSentTheInvoiceToYourEmail: 'We have sent the invoice to your email',
    weHaveSentReceiptToYourEmail: 'We have sent receipt to your email',
    wireMethods: 'SWIFT, ACH, BACS, SEPA, PayPal',
    invoiceHasBeen: {
      paid: 'The invoice has been paid for',
      refund: 'The invoice has been refunded for',
      chargedback: 'The invoice has been charged back for'
    },
    finalPaymentIsExpected:
      'Final payment of <money></money> is expected after current smart-contract is done.'
  },
  myItemsOnly: 'My items only',
  noPlural: 'No invoices',
  account: 'Status',
  payer: 'Payer',
  payBill: 'Pay bill',
  payFromDeposit: 'Pay from deposit',
  payFromAv: 'Pay from AV',
  paymentDate: 'Payment date',
  paymentForIsProcessing:
    'Payment <invoiceId></invoiceId> for <invoiceAmount></invoiceAmount> is processing',
  paymentMethod: 'Payment method',
  paymentMethodWithColon: 'Payment method:',
  payments: 'Payments',
  paymentProcessing: 'Payment processing',
  performerInvoices: 'Performer invoices',
  openPerformerInvoices: 'Open performer invoices',
  sentPerformerInvoices: 'Sent performer invoices',
  currentPerformerInvoices: 'Current performer invoices',
  overduePerformerInvoices: 'Overdue performer invoices',
  prepayment: 'Prepayment',
  prepaymentAmount: 'Prepayment amount',
  proformaInvoice: 'Proforma invoice',
  projectName: 'Project name',
  receivedAmount: 'Received amount',
  returnReason: 'Return reason',
  sender: 'Sender',
  search: 'Search invoices',
  searchByGeneralLedgerId: 'Search by general ledger id',
  searchById: 'Search by ID',
  searchByPayer: 'Search by payer',
  selectDraft: 'Select draft',
  status: 'Invoice status',
  updateDueDate: 'Update invoice due date',
  updateIssueDate: 'Update invoice issue date',
  updateInvoiceTag: 'Update invoice tag',
  view: 'View invoice',
  viewOpenInvoices: 'View open invoices',
  void: 'Void',
  wire: 'WIRE',
  selectPayerSubbook: 'Select payer subbook',
  statuses: {
    draft: 'Draft',
    open: 'Open',
    paid: 'Paid',
    prepaid: 'Prepaid',
    sent: 'Sent',
    canceled: 'Canceled',
    uncollectible: 'Uncollectible',
    void: 'Void',
    none: 'None',
    upcoming: 'Upcoming'
  },
  invoiceProcessingJobStatuses: {
    initialized: 'Initialized',
    processing: 'Processing',
    completed: 'Completed',
    failed: 'Failed'
  },
  grouping: {
    projectTask: 'project / task',
    projectSplit: 'project / split',
    batchTask: 'batch / task',
    monthInvoiceCreated: 'month invoice created',
    monthTaskCreated: 'month task created',
    monthTaskDueDate: 'month task due date',
    monthProjectCreated: 'month project created',
    monthTaskCreatedTask: 'month task created / task',
    monthTaskDueDateTask: 'month task due date / task',
    monthProjectCreatedProject: 'month project created / project',
    invoiceTag: 'invoice tag',
    split: 'split',
    batch: 'batch',
    monthTaskDone: 'month task done',
    monthProjectClosed: 'month project closed'
  }
};
